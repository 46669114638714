.main-container {
  width: 100%;
  height: auto;
  max-width: none;
  background-color: #fff;
  border-bottom: 1px solid #e3e3e3;
  justify-content: left;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 22px 4%;
  display: flex;
  position: sticky;
  top: 0;
  bottom: 1233460000px;
}
.boTag-logo {
  height: 35px;
  width: 142px;
}
