* {
  box-sizing: border-box;
}
.content {
  width: 100%;
  height: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.full-image {
  width: 100%;
  height: 100%;
  max-width: none;
  white-space: normal;
  word-break: normal;
  object-fit: cover;
  border-radius: 4px;
}
.main {
  overflow: hidden;
}
.heading-2 {
  min-width: 500px;
  color: var(--deep-grey);
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 47px;
  font-weight: 500;
  line-height: 1.1;
}
.heading-27 {
  font-size: 24px;
  font-weight: 400;
  margin: 0px !important;
}
.second-headings {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}
.description {
  width: 100%;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10%;
  padding-left: 4%;
  display: flex;
}
.button {
  max-width: 300px;
  border: 1px none var(--deep-grey);
  background-color: var(--deep-grey);
  color: #fff;
  border-radius: 64px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}
